<template>
  <h3 class="text-center mb-3">
    Geburtsdatum & <br />
    Sozialversicherungsnr.
    <h6>
      (dev only:
      <button @click.prevent="generateMockUp" class="btn btn-success p-2 mx-1">
        <font-awesome-icon icon="user-plus" class="px-0" />
      </button>
      <button @click.prevent="emptyForm" class="btn btn-danger p-2 mx-1">
        <font-awesome-icon icon="user-minus" class="px-0" /></button
      >)
    </h6>
  </h3>
  <form
    class="needs-validation"
    @submit.prevent="validateConsentData"
    novalidate
  >
    <label class="form-label mb-0"><h6>Geburtsdatum wählen:</h6></label>
    <VueDatePicker
      v-model="store.subject.dateOfBirth"
      :flow="dateFlow"
      locale="de"
      auto-apply
      :enable-time-picker="false"
      :max-date="new Date()"
      :start-date="startDate"
      class="mb-4 date-picker"
      :format="format"
    />

    <label class="form-label mb-0"><h6>Sozialversicherungsnummer:</h6></label>
    <div class="socsec-wrapper">
      <div class="form-floating" style="flex: 5">
        <input
          v-model="store.subject.socialSecurity"
          type="text"
          maxlength="4"
          class="form-control socsec-input first-four-input"
        />
        <div class="socsec-indicators">
          <span class="socsec-line"></span>
          <span class="socsec-line"></span>
          <span class="socsec-line"></span>
          <span class="socsec-line"></span>
        </div>
        <label for="floatingInput">Ersten 4 Stellen</label>
      </div>
      <div class="form-floating" style="flex: 5">
        <input
          type="text"
          maxlength="6"
          class="socsec-input last-six-input form-control bg-light text-muted"
          v-model="store.lastSixSocSecurity"
          readonly
        />

        <div class="socsec-indicators6">
          <span class="socsec-line6"></span>
          <span class="socsec-line6"></span>
          <span class="socsec-line6"></span>
          <span class="socsec-line6"></span>
          <span class="socsec-line6"></span>
          <span class="socsec-line6"></span>
        </div>
      </div>
    </div>
    <div class="socsec-error mt-2">
      <div v-if="store.temp.socialSecurityError === 2">
        Bitte geben Sie die ersten 4 Stellen Ihrer Sozialversicherungsnummer an.
      </div>
      <div v-if="store.temp.socialSecurityError === 3">
        Bitte geben Sie eine nur Zahlen an.
      </div>
      <div v-if="store.temp.socialSecurityError === 4">
        Geburtsdatum und Sozialversicherungsnr. passen nicht zusammen.
      </div>
    </div>
    <button
      type="submit"
      class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
      :disabled="isFormInvalid"
    >
      Weiter
    </button>
  </form>

  <AgeChangeModal />
</template>

<script setup>
import AgeChangeModal from "@/components/utils/AgeChangeModal.vue";
import { Modal } from "bootstrap";
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, computed, onMounted } from "vue";
import { samplePersons } from "@/assets/samplePersons";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
const startDate = ref(new Date(1950, 1));
const dateFlow = ref(["year", "month", "calendar"]);
const store = dhRegistrationStore();

const isFormInvalid = computed(() => {
  return (
    store.subject.dateOfBirth === "" ||
    store.subject.dateOfBirth === null ||
    store.subject.socialSecurity === ""
  );
});

const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}. ${month}. ${year}`;
};

function validateSocialSecurity() {
  store.temp.socialSecurityError = 1;

  // Check if 4 numbers are entered
  if (
    store.temp.socialSecurityError === 1 &&
    store.subject.socialSecurity.length !== 4
  ) {
    store.temp.socialSecurityError = 2;
  }

  // Check if entered value is only numbers
  if (
    store.temp.socialSecurityError === 1 &&
    !/^[0-9]+$/.test(store.subject.socialSecurity)
  ) {
    store.temp.socialSecurityError = 3;
  }

  // Check if Social Security Number is valid
  if (store.temp.socialSecurityError === 1) {
    var checkDigit = parseInt(store.subject.socialSecurity[3]);
    var numbersToCheck = store.subject.socialSecurity
      .slice(0, 3)
      .concat(store.lastSixSocSecurity);
    var controlNumbers = [3, 7, 9, 5, 8, 4, 2, 1, 6];
    var checkSum = 0;
    for (let i = 0; i < numbersToCheck.length; i++) {
      checkSum += parseInt(numbersToCheck[i]) * controlNumbers[i];
    }
    checkSum %= 11;
    if (checkSum !== checkDigit) {
      store.temp.socialSecurityError = 4;
    }
  }
}

function validateAge() {
  var dateOfBirth = new Date(store.subject.dateOfBirth);
  var ageDiff = Date.now() - dateOfBirth.getTime();
  var ageDate = new Date(ageDiff);
  store.temp.ageEntered = Math.abs(ageDate.getUTCFullYear() - 1970);
  if (Math.abs(store.temp.ageEntered - store.subject.age) > 1) {
    store.temp.ageValidationError = 1;
  } else {
    store.temp.ageValidationError = 2;
  }
}

function validateConsentData() {
  validateSocialSecurity();
  validateAge();

  if (
    store.temp.ageValidationError === 2 &&
    store.temp.socialSecurityError === 1
  ) {
    if (store.subject.age < 65) {
      store.abortAgeError = 1;
      store.step = 0;
    } else {
      store.subject.dateOfBirth = store.subject.dateOfBirth
        .toISOString()
        .split("T")[0];
      store.step++;
    }
  } else if (store.temp.ageValidationError == 1) {
    const ageChangeModalEl = document.getElementById("ageChangeModal");
    const ageChange = new Modal(ageChangeModalEl);
    ageChange.show();
  }
}

function generateMockUp() {
  let randomPerson =
    samplePersons[Math.floor(Math.random() * samplePersons.length)];
  store.subject.dateOfBirth = randomPerson.dateOfBirth;
  store.subject.socialSecurity = randomPerson.socialSecurity.slice(0, 4);
}

function emptyForm() {
  store.subject.dateOfBirth = "";
  store.subject.socialSecurity = "";
}

onMounted(() => {
  store.temp.ageValidationError = 0;
});
</script>

<style scoped>
.socsec-wrapper {
  display: flex;
  width: 100%;
}

.socsec-input {
  font-size: 16px;
  padding: 8px;
  letter-spacing: 0.5px;
  width: 100%; /* Ensures it fills its parent flex container */
  outline: none;
  height: 65px;
}

.first-four-input {
  font-size: 18px;
  letter-spacing: 5.2px; /* Adjust spacing between characters */
  font-family: "Lucida Console", monospace;
  text-align: end;
}

.last-six-input {
  font-size: 18px;
  letter-spacing: 5.2px; /* Adjust spacing between characters */
  font-family: "Lucida Console", monospace;
  text-align: start;
  padding-left: 14px;
}

/* Indicators below each input */
.socsec-indicators {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(4ch + 26px);
  bottom: 12px;
  right: 11px;
}

.socsec-line {
  flex: 1;
  height: 2px;
  background-color: #a7a7a7;
  margin: 0 2.5px;
}

/* Indicators below each input */
.socsec-indicators6 {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(6ch + 38px);
  bottom: 12px;
  left: 11px;
}

.socsec-line6 {
  flex: 1;
  height: 2px;
  background-color: #a7a7a7;
  margin: 0 2.5px;
}

.socsec-error {
  font-size: 14px;
  color: red;
}
</style>

<style>
.dp__input {
  height: 65px !important;
}
</style>
