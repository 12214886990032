<template>
  <div>
    <div class="progress position-relative">
      <div
        class="progress-bar progress-bar-striped"
        role="progressbar"
        :style="{ width: progress + '%' }"
      >
        <small class="justify-content-center d-flex position-absolute w-100"
          >Fortschritt</small
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";

const store = dhRegistrationStore();
const progress = computed(() => {
  return (store.step / 35) * 100;
});
</script>

<style scoped>
.progress {
  height: 30px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(216, 212, 212);
}
.progress-bar {
  background-color: #142b58;
}

.fixed-bottom {
  box-shadow: 0px 4px 10px 1px black;
}

small {
  font-size: 14px;
  color: rgb(255, 255, 255);
}
</style>
