<template>
  <h2>Das müssen Sie jetzt tun!</h2>
  <div class="row justify-content-center align-items-center mt-4">
    <h5 class="text-start">
      1. Sie haben Post! <font-awesome-icon icon="fa-envelope" class="ps-2" />
    </h5>
    <span class="info-text"
      >Sie erhalten eine Email auf die von Ihnen angegebene Email-Adresse ({{
        store.subject.email
      }}). Bitte klicken Sie in dieser Email auf den Link um ein Passwort zu
      wählen und damit Ihren Account zu aktivieren.</span
    >
    <div class="col-3 col-md-2"></div>
  </div>
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <h5 class="text-start">
      2. App herunterladen!
      <font-awesome-icon icon="fa-mobile" class="ps-2" />
    </h5>
    <span class="info-text"
      >Bitte laden Sie anschließend die Pulskontrolle-App für Ihr Smartphone
      herunter und melden Sie sich in der App mit der von Ihnen angegebenen
      Email-Adresse und dem von Ihnen gewählten Passwort an.</span
    >
  </div>

  <div class="row justify-content-center">
    <div
      class="col-9 col-sm-8 justify-content-center d-flex"
      v-if="store.osName !== 'ios'"
    >
      <a href="javascript:void(0)" class="text-center" :disabled="true"
        ><img
          alt="Jetzt bei Google Play"
          src="img/androidBadge.png"
          class="fit-image"
      /></a>
    </div>
    <div
      class="col-9 col-sm-8 justify-content-center d-flex"
      v-if="store.osName !== 'android'"
    >
      <a href="javascript:void(0)" class="text-center my-3"
        ><img
          alt="Jetzt im AppStore"
          src="img/appleBadge.png"
          class="fit-image"
      /></a>
    </div>
  </div>
  <div class="row justify-content-center align-items-center mt-4 mb-3">
    <h5 class="text-start">
      3. Fertig!
      <font-awesome-icon icon="fa-circle-check" class="ps-2" />
    </h5>
    <span class="info-text"
      >Sie können nun mit den Messungen in der Pulskontrolle-App starten. Viel
      Spaß!</span
    >
  </div>
  <button
    data-bs-toggle="modal"
    data-bs-target="#restartModal"
    class="btn btn-outline-danger w-100 my-4"
  >
    <font-awesome-icon icon="fa-rotate-right" class="px-1" /> Neue Registrierung
  </button>

  <RestartModal />
</template>

<script setup>
import RestartModal from "@/components/utils/RestartModal.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
.fit-image {
  width: 100%;
  object-fit: cover;
}

.alert-fixed {
  opacity: 0.95;
  position: fixed;
  bottom: 5%;
  left: 10%;
  width: 80%;
  z-index: 9999;
  border-radius: 0px;
  font-size: 14px;
}
.info-text {
  font-size: 14px;
}
</style>
