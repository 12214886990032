export const riskQuestions = [
    {
        "type": "bool",
        "text": "Wurde bei Ihnen eine Gefäßerkrankung am Herzen mit CT oder Herzkatheter festgestellt?",
        "fieldName": "angiopathyConfirmed",
        "medicusMessage": "Platzhalter",
    },
    // {
    //     "type": "boolUncertainty",
    //     "text": "Wurde bei Ihnen eine andere Gefäßerkrankung fesgestellt?",
    //     "fieldName": "angiopathy",
    //     "medicusMessage": "Platzhalter",
    // },
    {
        "type": "bool",
        "text": "Haben Sie einen Herzschrittmacher?",
        "fieldName": "pacemaker",
        "medicusMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben Sie Diabetes oder werden deshalb behandelt?",
        "fieldName": "diabetes",
        "medicusMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Hatten Sie schon einen Herzinfarkt?",
        "fieldName": "myocardialInfarction",
        "medicusMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben Sie Bluthochdruck oder werden deshalb behandelt?",
        "fieldName": "hypertension",
        "medicusMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Leiden Sie an Herzschwäche oder werden deshalb behandelt?",
        "fieldName": "heartFailure",
        "medicusMessage": "Typische Anzeichen einer Herzschwäche wären es, wenn Sie regelmäßig geschwollene Beine haben, häufig unter Müdigkeit leiden oder öfters Atemnot verspüren.",
    },
    {
        "type": "bool",
        "text": "Hatten Sie schon einen Schlaganfall?",
        "fieldName": "stroke",
        "medicusMessage": "Platzhalter",
    },
    {
        "type": "bool",
        "text": "Haben oder hatten Sie eine Krebserkrankung?",
        "fieldName": "cancer",
        "medicusMessage": "Platzhalter",
    },
    // {
    //     "type": "multipleChoiceMedication",
    //     "text": "Nehmen Sie die folgenden Medikamente?",
    //     "fieldName": "medications",
    //     "medicusMessage": "",
    // },
    {
        "type": "boolExtended",
        "text": "Rauchen Sie?",
        "extendedText": "Wie viele Zigaretten rauchen Sie <b>pro Tag </b>?",
        "extendedLabels": ["<10", "10-20", "20-40", ">40"],
        "extendedValues": ["<10", "10-20", "20-40", ">40"],
        "orientation": "horizontal",
        "fieldName": "smoking",
        "medicusMessage": "Platzhalter",
    },
    // { old alcohol question
    //     "type": "boolExtended",
    //     "text": "Trinken Sie regelmäßig Alkohol?",
    //     "extendedText": "Wie viele alkoholische Getränke trinken Sie <b>pro Tag</b>?",
    //     "extendedLabels": ["<2", "2-4", "4-6", ">6"],
    //     "extendedValues": ["<2", "2-4", "4-6", ">6"],
    //     "fieldName": "alcohol",
    //     "medicusMessage": "Platzhalter",
    // },
    {
        "type": "boolExtended",
        "text": "Trinken Sie gelegentlich Alkohol?",
        "extendedText": "Wie oft trinken Sie Alkohol?",
        "extendedLabels": ["1 Mal im Monat", "2-4 Mal im Monat", "3-4 Mal in der Woche", "Öfter als 4 Mal in der Woche"],
        "extendedValues": ["1/Monat", "2-4/Monat", "3-4 Mal/Woche", ">4/Woche"],
        "orientation": "vertical",
        "fieldName": "alcohol",
        "medicusMessage": "Platzhalter",
    },
    {
        "type": "boolExtended",
        "text": "Machen Sie regelmäßig Sport oder gehen Sie spazieren?",
        "extendedText": "Wie oft betätigen Sie sich körperlich <b>in der Woche</b>?",
        "extendedLabels": ["<2", "2-4", "4-6", ">6"],
        "extendedValues": ["<2", "2-4", "4-6", ">6"],
        "orientation": "horizontal",
        "fieldName": "regularlyActive",
        "medicusMessage": "Platzhalter",
    },
]
