<template>
  <h2>Unterschrift</h2>
  <h6>
    Mit der nun folgenden Unterschrift, bestätigen Sie, dass Sie an der Studie
    teilnehmen möchten.
  </h6>
  <h6 v-if="store.osName === 'windows' || store.osName === 'unknown'">
    Unterschreiben Sie mit der Maus im unteren Feld.
  </h6>
  <h6 v-else>Unterschreiben Sie mit Ihrem Finger im unteren Feld.</h6>
  <div class="pad-container">
    <VueSignaturePad
      width="100%"
      height="175px"
      ref="signaturePad"
      class="signature-pad"
      :class="[signatureLocked ? 'locked' : '']"
      :options="{
        onEnd: () => {
          updateSignature();
        },
      }"
    />
  </div>
  <DrawSignature v-if="!store.subject.signature" />

  <div class="row pad-row justify-content-start mx-0 mb-3">
    <div class="col-6 col-sm-4 mx-0 px-0">
      <button
        @click.prevent="clear()"
        class="btn btn-danger btn-del w-100 py-2 px-1 mt-2"
      >
        <span v-if="signatureLocked"
          ><font-awesome-icon icon="rotate-left" class="px-2" /> Neue
          Unterschrift
        </span>
        <span v-if="!signatureLocked"
          ><font-awesome-icon icon="eraser" class="px-2" /> Löschen</span
        >
      </button>
    </div>
  </div>
  <div class="consent-text mb-4 text-center">
    Ich erkläre mich mit der Teilnahme an dieser Forschungsstudie einverstanden.
    Ich bestätige, dass meine von mir angegebenen persönlichen Daten korrekt
    sind und verwendet werden dürfen. Ich bestätige weiters, dass ich diese
    Einverständniserklärung in meinem eigenen Namen ausfülle und dass meine
    elektronische Unterschrift, die durch das Ausfüllen des obigen Feldes
    dokumentiert wird, das rechtsverbindliche Äquivalent meiner
    handschriftlichen Unterschrift auf dieser Einverständniserklärung ist.
  </div>
  <button
    type="submit"
    class="btn btn-proceed btn-lg w-100 mt-1 mb-5"
    @click.prevent="store.step++"
    :disabled="!store.subject.signature"
  >
    Speichern & weiter
  </button>
</template>

<script setup>
import DrawSignature from "@/components/utils/DrawSignature.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
import { ref, onMounted } from "vue";

const store = dhRegistrationStore();
const padEmpty = ref(false);
const signatureLocked = ref(false);
const signaturePad = ref(null);

const changeButton = (buttonState) => {
  padEmpty.value = buttonState;
};

const clear = () => {
  signaturePad.value.clearSignature();
  store.subject.signature = "";
  signaturePad.value.openSignaturePad();
  signatureLocked.value = false;
  changeButton(false);
};

const updateSignature = () => {
  const { isEmpty, data } = signaturePad.value.saveSignature("image/svg+xml");
  if (!isEmpty) {
    store.subject.signature = data;
  }
};

onMounted(() => {
  signaturePad.value.fromDataURL(store.subject.signature);
  if (store.subject.signature !== "") {
    signaturePad.value.lockSignaturePad();
    signatureLocked.value = true;
  }
});
</script>

<style scoped>
.consent-text {
  font-size: 12px;
}
.pad-container {
  border: double 2px black;
  text-align: center;
  vertical-align: center;
}

.locked {
  background-color: rgb(233, 233, 233);
}

.btn-del {
  font-size: 18px;
}

.signature-box {
  position: relative;
  width: 200px;
  height: 50px;
}

.signature-path {
  stroke: black;
  stroke-width: 2;
  fill: transparent;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000; /* Start hidden */
}

@keyframes draw {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.hand-icon {
  position: absolute;
  top: 10px; /* Adjust position as needed */
  left: 10px; /* Start from the left */
  width: 30px; /* Size of the hand icon */
  height: 30px;
}

@keyframes moveHand {
  0% {
    top: 30px;
    left: 10px;
  } /* Starting point */
  100% {
    top: 20px;
    left: 130px;
  } /* End point (match the path end) */
}
</style>
