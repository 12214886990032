<template>
  <button
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#bugReportModal"
    class="btn btn-outline-info px-2 ms-2"
    :disabled="store.status !== 'in-progress'"
  >
    <font-awesome-icon icon="fa-bug" class="px-1" />
  </button>
  <BugReportModal />
</template>

<script setup>
import BugReportModal from "@/components/utils/BugReportModal.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
