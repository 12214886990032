<template>
  <h2 class="mt-4">Worum geht's?</h2>
  <div class="row mt-3">
    <div class="col-12">
      <div class="text-center">
        Sehen Sie sich dieses kurze Video an, um das Wichtigste über das Projekt
        <b>Pulskontrolle</b>
        zu erfahren!
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <video
      controls
      class="my-4 vert_video"
      preload="none"
      poster="../../../../public/img/infoVid_thumbnail.png"
      playsinline
    >
      <source src="../../../../public/vid/infoVid_v0.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script setup></script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.fit-image {
  max-width: 75px;
}

h6 {
  text-align: start !important;
}

@media (max-width: 480px) {
  .vert_video {
    width: 100%;
    height: 100%;
  }
}

.vert_video {
  width: 60%;
  height: 60%;
}
</style>
