<template>
  <transition>
    <section v-if="store.step === 33">
      <RandomStartScreen /><br />
      <NextButton />
    </section>
  </transition>

  <transition>
    <section v-if="store.step === 34"><RandomCoinScreen /><br /></section>
  </transition>

  <transition>
    <section v-if="store.step === 35"><RandomEndScreen /><br /></section>
  </transition>
  <transition>
    <section v-if="store.step === 36">
      <RandomEndInstructionScreen /><br />
    </section>
  </transition>
</template>

<script setup>
import RandomStartScreen from "@/components/screens/random/RandomStartScreen.vue";
import RandomCoinScreen from "@/components/screens/random/RandomCoinScreen.vue";
import RandomEndScreen from "@/components/screens/random/RandomEndScreen.vue";
import RandomEndInstructionScreen from "@/components/screens/random/RandomEndInstructionScreen.vue";
import NextButton from "@/components/navigation/NextButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped></style>
