<template>
  <h6>Frage 1 von 3</h6>
  <form class="needs-validation mt-4" @submit.prevent="validateAge" novalidate>
    <h5 class="text-center">Wie alt sind Sie?</h5>
    <div class="form-floating mt-4 mb-3">
      <input
        type="number"
        class="form-control"
        :class="[
          ageError === 1 ? 'is-invalid' : '',
          ageError === 2 ? 'is-valid' : '',
        ]"
        id="floatingInput"
        placeholder="75"
        v-model="store.subject.age"
      />
      <label for="floatingInput">Alter</label>
      <div class="invalid-feedback">
        Bitte geben Sie Ihr korrekter Alter als Zahl ein (z.B. 76).
      </div>
    </div>
    <button
      type="submit"
      :disabled="isFormInvalid"
      class="btn btn-proceed btn-lg w-100 mt-5 mb-5"
    >
      Weiter
    </button>
  </form>
</template>

<script setup>
import { ref, computed } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
const ageError = ref(0);

const isFormInvalid = computed(() => {
  return !store.subject.age;
});

function validateAge() {
  if (
    store.subject.age < 0 ||
    store.subject.age > 150 ||
    store.subject.age !== parseInt(store.subject.age, 10)
  ) {
    ageError.value = 1;
  } else {
    if (store.subject.age < 65) {
      store.abortAgeError = 1;
      store.step = 0;
    } else {
      store.step++;
    }
  }
}
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
