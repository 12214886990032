<template>
  <div class="icon-container">
    <svg id="svg-path" width="100%">
      <path
        id="path"
        :class="isAnimating ? 'animate-line' : ''"
        d="M 35 100 c
      32.2828 -1.756 83.9272 -59.4146 75.0744 -60.3982 c -24.8649 -2.7628
      -60.9475 48.832 -33.3037 58.7048 c 22.0688 7.8817 32.5835 -17.4985 52.4956
      -17.4985 c 10.6107 0 14.5502 12.6422 24.8367 14.1117 c 36.2827 5.1832
      75.0655 3.5511 111.7649 5.0802"
      />
    </svg>
    <div
      id="icon-wrapper"
      v-if="isIconVisible"
      :class="{ 'animate-icon': isAnimating }"
    >
      <font-awesome-icon
        ref="icon"
        :icon="
          store.osName === 'windows' ? 'fa-arrow-pointer' : 'fa-hand-pointer'
        "
        id="finger"
        class="px-2"
        style="font-size: 25px"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { dhRegistrationStore } from "@/stores/dh-registration";

const store = dhRegistrationStore();

// State variables
const isAnimating = ref(false); // To apply the animation class
const isIconVisible = ref(false); // To control visibility

const startAnimationSequence = async () => {
  while (true) {
    // Step 1: Wait for 6 seconds
    isIconVisible.value = false; // Ensure the icon is hidden initially
    await delay(4000);

    // Step 2: Start animation
    isIconVisible.value = true; // Make the icon visible
    isAnimating.value = true;

    // Wait for the animation to finish (assuming it's 1s long, adjust if needed)
    await delay(1000);

    // Step 3: Keep the icon visible for 1 second after the animation
    await delay(2000);

    // Step 4: Hide the animation
    isAnimating.value = false;
    isIconVisible.value = false;
  }
};

// Helper function to wait for a specified time
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

onMounted(() => {
  startAnimationSequence();
});
</script>

<style scoped>
.icon-container {
  position: relative;
  width: 95%;
  top: -165px;
  margin-bottom: -145px;
  left: 2.5%;
  z-index: -1;
}

#path {
  stroke: #000;
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 500; /* Adjust to match path length */
  stroke-dashoffset: 500; /* Initially offset the stroke */
}

.animate-line {
  animation: drawLine 2.7s linear forwards;
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0; /* Fully draw the line */
  }
}

#icon-wrapper {
  position: absolute;
  top: 18px;
  offset-rotate: 0deg;
  offset-path: path(
    "M 35 100 c 32.2828 -1.756 83.9272 -59.4146 75.0744 -60.3982 c -24.8649 -2.7628 -60.9475 48.832 -33.3037 58.7048 c 22.0688 7.8817 32.5835 -17.4985 52.4956 -17.4985 c 10.6107 0 14.5502 12.6422 24.8367 14.1117 c 36.2827 5.1832 75.0655 3.5511 111.7649 5.0802"
  );
}

.animate-icon {
  animation: moveAlongPath 2s linear forwards;
}

@keyframes moveAlongPath {
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
}

#icon-wrapper i {
  font-size: 24px;
}
</style>
