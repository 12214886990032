<template>
  <h2>Super!</h2>
  <div class="text-center info-text">
    <span>
      Sie erfüllen alle Voraussetzungen, um am wissenschaftlichen Projekt
      <b>Pulskontrolle</b> teilzunehmen!<br />
      <br />
      Schauen Sie sich auf der nächsten Seite ein Video an, das Sie über die
      Teilnahme informiert.<br
    /></span>
  </div>
  <button
    @click.prevent="store.step++"
    class="btn btn-proceed btn-lg w-100 mt-4 mb-3"
  >
    Zum Video<font-awesome-icon icon="fa-circle-right" class="px-3" />
  </button>
  <div class="row justify-content-center align-items-center mt-3">
    <div class="col">
      <img src="img/logoLBG.png" class="img-responsive fit-image" />
    </div>
    <div class="col">
      <img src="img/logoMUI.png" class="img-responsive fit-image" />
    </div>
    <div class="col">
      <img src="img/logoBMBWF.png" class="img-responsive fit-image" />
    </div>
  </div>
  <div class="row justify-content-center align-items-center mt-5">
    <div class="col">
      <img src="img/logoAIT.png" class="img-responsive fit-image" />
    </div>
    <div class="col">
      <img src="img/logoMUG.png" class="img-responsive fit-image" />
    </div>
    <div class="col">
      <img src="img/logoUMIT.jpg" class="img-responsive fit-image" />
    </div>
    <div class="col">
      <img src="img/logoTirolKliniken.png" class="img-responsive fit-image" />
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
video {
  width: 100%;
  height: 250px;
}
.info-text {
  font-size: 14px;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}
</style>
