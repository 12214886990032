<template>
  <div
    class="modal fade"
    id="bugReportModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cancelModalLabel">
            <font-awesome-icon icon="fa-bug" class="px-1" />
            Bug-Report Information
          </h5>
        </div>
        <div class="modal-body">
          Bitte zeigen Sie dies Ihrem freundlichen Web-Entwickler :).

          <div class="mt-4">
            User-Agent:<br />
            {{ store.userAgent }} <br />
            <br />
            Timestamp: <br />
            {{ datetime }} <br />
            <br />
            Step: <br />
            {{ store.step }}
            <br />
            <br />
            App Version: <br />
            {{ applicationVersion }}
          </div>
        </div>
        <div class="modal-footer py-2">
          <button type="button" class="btn btn-info" data-bs-dismiss="modal">
            Schließen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { dhRegistrationStore } from "@/stores/dh-registration";
import { version } from "../../../package.json";

var applicationVersion = version;
const store = dhRegistrationStore();
var currentdate = new Date();
var datetime =
  currentdate.getDate() +
  "/" +
  (currentdate.getMonth() + 1) +
  "/" +
  currentdate.getFullYear() +
  " @ " +
  currentdate.getHours() +
  ":" +
  currentdate.getMinutes() +
  ":" +
  currentdate.getSeconds();
</script>

<style scoped></style>
