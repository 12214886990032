<template>
  <h6>Frage 3 von 3</h6>
  <h5 class="my-3">Nehmen Sie <u>eines der folgenden</u> Medikamente?</h5>
  <div class="btn-group-vertical btn-group-question" role="group">
    <input
      type="radio"
      class="btn-check"
      id="xarelto"
      value="xarelto"
      @click="store.eligibility.anticoagulantBool = true"
      v-model="store.eligibility.anticoagulant"
    />
    <label class="btn btn-outline-primary" for="xarelto"
      >Xarelto (Rivaroxaban)</label
    >
    <input
      type="radio"
      class="btn-check"
      id="pradaxa"
      value="pradaxa"
      @click="store.eligibility.anticoagulantBool = true"
      v-model="store.eligibility.anticoagulant"
    />
    <label class="btn btn-outline-primary" for="pradaxa"
      >Pradaxa (Dabigatran)</label
    >
    <input
      type="radio"
      class="btn-check"
      id="eliquis"
      value="eliquis"
      @click="store.eligibility.anticoagulantBool = true"
      v-model="store.eligibility.anticoagulant"
    />
    <label class="btn btn-outline-primary" for="eliquis"
      >Eliquis (Apixaban)</label
    >
    <input
      type="radio"
      class="btn-check"
      id="lixiana"
      value="lixiana"
      @click="store.eligibility.anticoagulantBool = true"
      v-model="store.eligibility.anticoagulant"
    />
    <label class="btn btn-outline-primary" for="lixiana"
      >Lixiana (Edoxaban)</label
    >
    <input
      type="radio"
      class="btn-check"
      id="sintrom"
      value="sintrom"
      @click="store.eligibility.anticoagulantBool = true"
      v-model="store.eligibility.anticoagulant"
    />
    <label class="btn btn-outline-primary" for="sintrom"
      >Sintrom (Marcumar)</label
    >
  </div>

  <div class="btn-group-vertical btn-group-question" role="group">
    <input
      type="radio"
      class="btn-check"
      id="no_anticoagulant"
      value="none"
      @click="store.eligibility.anticoagulantBool = false"
      v-model="store.eligibility.anticoagulant"
    />
    <label class="btn btn-outline-danger" for="no_anticoagulant"
      ><span>Ich nehme <u>keines dieser</u> Medikamente</span></label
    >
  </div>
  <br />
  <NextButton :disabled="store.eligibility.anticoagulant === ''" />
  <MedicusButton
    :message="'Antikoagulanzien, gemeinhin als Blutverdünner bekannt, sind Medikamente, die zur Verhinderung von Blutgerinnseln beitragen. Sie werden Personen verabreicht, die ein hohes Risiko für Blutgerinnsel haben, um ihr Risiko für schwere Krankheitsbilder wie Schlaganfälle und Herzinfarkte zu verringern. Beispiele sind Xarelto (Rivaroxaban), Pradaxa (Dabigatran), Eliquis (Apixaban) und Lixiana (Edoxaban). Wenn Sie sich nicht sicher sind, ob Sie Blutverdünner einnehmen, fragen Sie bitte Ihren Arzt.'"
  />
</template>

<script setup>
import NextButton from "@/components/navigation/NextButton.vue";
import MedicusButton from "@/components/utils/MedicusButton.vue";
import { dhRegistrationStore } from "@/stores/dh-registration";
const store = dhRegistrationStore();
</script>

<style scoped>
.btn-check + .btn {
  color: #142b58;
  border-color: #142b58;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-check:unchecked + .btn {
  color: #142b58;
}
.btn-check:checked + .btn {
  color: white;
  background-color: #142b58;
}
</style>
